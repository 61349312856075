/* eslint-disable react/prop-types */
import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { IeltsDifference } from 'shared/CourseIeltsDetail/component/IeltsDifference';

const ieltsCapTocCourseDifferencesQuery = graphql`
  query ieltsCoBanCourseDifferencesQuery {
    gcms {
      jsonContanier(where: { name: "BasicIelts_Diff" }) {
        content
      }
    }
  }
`;

const IeltsCoBanDifferencesMain = () => {
  return (
    <StaticQuery
      query={ieltsCapTocCourseDifferencesQuery}
      render={data => {
        return (
          <IeltsDifference
            data={data.gcms.jsonContanier.content}
            level="basic"
          />
        );
      }}
    />
  );
};

export default IeltsCoBanDifferencesMain;
