import * as React from 'react';

import Layout from 'shared/CourseIeltsDetail/Layout';
import IeltsCoBanDifferencesMain from 'shared/CourseIeltsDetail/section/IeltsCoBanDifferencesMain';
import { Course } from 'shared/CourseIeltsDetail/section/Course';
import Problem from 'shared/CourseIeltsDetail/section/Problem';

const ieltsDesc =
  'Nắm chắc tư duy đọc, viết, nói 1 câu tiến đến đọc, viết, nói thành thạo 1 đoạn. Quan trọng nhất ở giai đoạn này là mình cần xây nền chắc chắn, học bản chất để bài thi IELTS hay TOEIC hay bất cứ bài thi Tiếng Anh nào cũng như nhau cả.';

const CoursePage = ({ location }) => {
  return (
    <Layout
      slug={location.pathname}
      title="IELTS"
      highlight="cơ bản"
      ieltsDesc={ieltsDesc}
      featureImg="tSOQyz9SQ7q287EbpJy2"
      seoTitle="Tổng hợp khóa học IELTS cho người mới bắt đầu kèm lộ trình đầy đủ"
      seoDesc="Lộ trình tự học IELTS cơ bản cho người mới bắt đầu từ 0 lên 7.0+, luyện thi IELTS toàn diện 4 kỹ năng cho người mất gốc, tài liệu IELTS cơ bản đến nâng cao."
    >
      <Problem level="basic" />
      <IeltsCoBanDifferencesMain />
      <Course title="Các khóa IELTS cơ bản tại DOL" level="Khóa IELTS cơ bản" />
    </Layout>
  );
};

export default React.memo(CoursePage);
